<script lang="ts">
	import Spinner from '~/components/Spinner.svelte'
	import { Events, getToken, Performers, Venues } from '~/lib/tn'
	import {
		addRecentSearch,
		recentlyViewed,
		recentSearches,
	} from '~/stores/local'
	import { format, parseISO, isSameYear } from 'date-fns'
	import { onMount } from 'svelte'
	import {
		Calendar,
		Icon,
		BuildingLibrary,
		MagnifyingGlass,
		MagnifyingGlassCircle,
		UserCircle,
	} from 'svelte-hero-icons'
	import { clickOutside, generateLink, slug } from '~/lib/utils'
	import { track } from '~/lib/va'

	let value = ''
	let venues = []
	let performers = []
	let events = []
	let searchedEvents = []
	let categories = []
	// let categories = [
	// 	{ title: 'Concerts', href: '/concerts' },
	// 	{ title: 'Sports', href: '/sports' },
	// 	{ title: 'NFL', href: '/sports' },
	// 	{ title: 'MLB', href: '/sports' },
	// 	{ title: 'NBA', href: '/sports' },
	// 	{ title: 'NHL', href: '/sports' }
	// ]
	let loading = false
	let hasSearched = false
	let open = false

	onMount(async () => {
		// search()
		// getToken()
	})

	$: if (!value) reset()

	function handleKeydown(e) {
		if (e.key === 'Enter' && value) {
			search()
		}
	}

	async function search() {
		hasSearched = true
		loading = true
		const t = performance.now()
		;[venues, performers, events] = await Promise.all([
			Venues.suggest({ q: value, numberOfSuggestions: 5 }),
			Performers.suggest({ q: value, numberOfSuggestions: 5 }),
			Events.suggest({
				q: value,
				numberOfSuggestions: 30,
				includeVenueInfo: true,
			}),
			// Events.search({ q: value, perPage: 30 }),
		])
		addRecentSearch(value)
		track('Search', { q: value })
		// console.log(searchedEvents)
		console.debug(performance.now() - t)

		loading = false

		// Tests
		// let p1 = performance.now()
		// await Events.suggest({
		// 	q: value,
		// 	numberOfSuggestions: 15,
		// 	includeVenueInfo: true,
		// })
		// console.log(performance.now() - p1, 'Suggest')

		// p1 = performance.now()
		// await Events.search({ q: value, perPage: 30 })
		// console.log(performance.now() - p1, 'Search')
	}

	function reset() {
		venues = []
		events = []
		performers = []
		hasSearched = false
	}

	function getItemIcon(type) {
		if (type === 'venue') return BuildingLibrary
		if (type === 'performer') return UserCircle
		if (type === 'event') return Calendar
	}
</script>

<div class="relative z-50" role="dialog" aria-modal="true">
	<!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
	<!-- {#if open}
		<div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
	{/if} -->
	<div class="absolute inset-0 z-50 text-left">
		<!--
      Command palette, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 scale-95"
        To: "opacity-100 scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 scale-100"
        To: "opacity-0 scale-95"
    -->
		<div
			class="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-lg bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all sm:rounded-xl"
			use:clickOutside
			on:outclick={() => (open = false)}
		>
			<div class="relative">
				<Icon
					src={MagnifyingGlass}
					solid
					class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
				/>
				<input
					on:focus={() => (open = true)}
					bind:value
					on:keydown={handleKeydown}
					type="text"
					class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-sm text-gray-800 placeholder-gray-400 focus:ring-0"
					placeholder="Search..."
				/>
			</div>

			{#if loading}
				<Spinner />
			{/if}
			{#if open}
				<!-- {$recentlyViewed?.length} -->
				{#if open && (!hasSearched || value)}
					<!-- Default state, show/hide based on command palette state. -->
					<ul class="scroll-py-2 divide-y divide-gray-100 overflow-y-auto">
						{#if $recentSearches?.length && !hasSearched}
							<li class="p-2">
								<h2 class="mb-2 mt-4 px-3 text-xs font-semibold text-gray-500">
									Past Searches
								</h2>
								<ul class="text-sm text-gray-700">
									<!-- Active: "bg-indigo-600 text-white" -->
									{#each $recentSearches as item}
										<span
											on:click={() => (value = item) && search()}
											on:keydown={() => {}}
										>
											<li
												class="group flex cursor-pointer select-none items-center rounded-md px-3 py-2 hover:bg-gray-100"
											>
												<!-- Active: "text-white", Not Active: "text-gray-400" -->
												<Icon
													src={MagnifyingGlassCircle}
													class="h-5 w-5 text-gray-400"
												/>
												<span class="ml-3 flex-auto truncate">{item}</span>
												<!-- Not Active: "hidden" -->
												<span class="ml-3 hidden flex-none text-indigo-100"
													>Jump to...</span
												>
											</li>
										</span>
									{/each}
								</ul>
							</li>
						{/if}
						{#if $recentlyViewed?.length && !hasSearched}
							<li class="p-2">
								<h2 class="mb-2 mt-4 px-3 text-xs font-semibold text-gray-500">
									Recently viewed
								</h2>
								<ul class="text-sm text-gray-700">
									<!-- Active: "bg-indigo-600 text-white" -->
									{#each $recentlyViewed as { title, url, id, type }}
										<a href={generateLink[type](title, id)}>
											<li
												class="group flex cursor-pointer select-none items-center rounded-md px-3 py-2 hover:bg-gray-100"
											>
												<!-- Active: "text-white", Not Active: "text-gray-400" -->
												<Icon
													src={getItemIcon(type)}
													class="h-5 w-5 text-gray-400"
												/>
												<span class="ml-3 flex-auto truncate">{title}</span>
												<!-- Not Active: "hidden" -->
												<span class="ml-3 hidden flex-none text-indigo-100"
													>Jump to...</span
												>
											</li>
										</a>
									{/each}
								</ul>
							</li>
						{/if}
						{#if categories.length}
							<li class="p-2">
								<h2 class="sr-only">Categories</h2>
								<ul class="text-sm text-gray-700">
									<!-- Active: "bg-indigo-600 text-white" -->
									{#each categories as { title, href }}
										<a {href}>
											<li
												class="group flex cursor-pointer select-none items-center rounded-md px-3 py-2 hover:bg-gray-100"
											>
												<!-- Active: "text-white", Not Active: "text-gray-400" -->
												<!-- <Icon src={Users} class="h-6 w-6 flex-none text-gray-400" /> -->
												<span class="ml-3 flex-auto truncate">{title}</span>
												<!-- Active: "text-indigo-100", Not Active: "text-gray-400" -->
												<!-- <span class="ml-3 flex-none text-xs font-semibold text-gray-400">
												<kbd class="font-sans">⌘</kbd>
												<kbd class="font-sans">N</kbd>
											</span> -->
											</li>
										</a>
									{/each}
								</ul>
							</li>
						{/if}
					</ul>
				{/if}

				<!-- Results, show/hide based on command palette state. -->
				{#if performers.length}
					<div>
						<h2 class="mb-2 mt-4 px-3 text-xs font-semibold text-gray-500">
							Performers
						</h2>
						<ul class="max-h-96 overflow-y-auto p-2 text-sm text-gray-700">
							<!-- Active: "bg-indigo-600 text-white" -->
							{#each performers as performer}
								<!-- Active: "bg-indigo-600 text-white" -->
								<!-- <a href="/performers/{performer.id}"> -->

								<a href={generateLink.performer(performer.name, performer.id)}>
									<li
										class="group flex cursor-pointer select-none items-center rounded-md px-3 py-2"
									>
										<!-- Active: "text-white", Not Active: "text-gray-400" -->
										<Icon
											src={UserCircle}
											class="h-6 w-6 flex-none text-gray-400"
										/>
										<span class="ml-3 flex-auto truncate font-semibold"
											>{performer.name}</span
										>
										<!-- Not Active: "hidden" -->
										<span class="ml-3 hidden flex-none text-indigo-100"
											>Jump to...</span
										>
									</li>
								</a>
							{/each}
						</ul>
					</div>
				{/if}

				{#if venues.length}
					<div>
						<h2 class="mb-2 mt-4 px-3 text-xs font-semibold text-gray-500">
							Venues
						</h2>
						<ul class="max-h-96 overflow-y-auto p-2 text-sm text-gray-700">
							<!-- Active: "bg-indigo-600 text-white" -->
							{#each venues as { name, id }}
								<!-- Active: "bg-indigo-600 text-white" -->
								<!-- <a href="/venues/{id}"> -->
								<a href={generateLink.venue(name, id)}>
									<li
										class="group flex cursor-pointer select-none items-center rounded-md px-3 py-2"
									>
										<!-- Active: "text-white", Not Active: "text-gray-400" -->
										<Icon
											src={BuildingLibrary}
											class="h-6 w-6 flex-none text-gray-400"
										/>
										<span class="ml-3 flex-auto truncate font-semibold"
											>{name}</span
										>
										<!-- Not Active: "hidden" -->
										<span class="ml-3 hidden flex-none text-indigo-100"
											>Jump to...</span
										>
									</li>
								</a>
							{/each}
						</ul>
					</div>
				{/if}

				{#if events.length}
					<div>
						<h2 class="mb-2 mt-4 px-3 text-xs font-semibold text-gray-500">
							Events
						</h2>
						<ul class="max-h-96 overflow-y-auto p-2 text-sm text-gray-700">
							<!-- Active: "bg-indigo-600 text-white" -->
							{#each events as ev}
								<!-- {JSON.stringify(ev)} -->
								<!-- Active: "bg-indigo-600 text-white" -->
								<a href={generateLink.event(ev.name, ev.id)}>
									<!-- <a href="/events/{ev.id}"> -->
									<li
										class="group flex cursor-pointer select-none items-center rounded-md px-3 py-2 hover:bg-gray-100"
									>
										<!-- Active: "text-white", Not Active: "text-gray-400" -->
										<Icon
											src={Calendar}
											class="h-6 w-6 flex-none text-gray-400"
										/>
										<div class="flex w-full items-center justify-between">
											<div>
												<span class="ml-3 flex-auto truncate font-semibold"
													>{ev.name}</span
												>
												{#if ev.venue}
													<p class="ml-3 flex-auto truncate text-gray-500">
														{ev.venue?.text?.name}
													</p>
												{/if}
											</div>
											<span
												class="ml-3 flex-auto truncate text-right text-gray-500"
												>{format(
													parseISO(ev.date),
													isSameYear(parseISO(ev.date), new Date())
														? 'MMM d'
														: 'MMM d yyyy',
												)}</span
											>
										</div>
										<!-- Not Active: "hidden" -->
										<span class="ml-3 hidden flex-none text-indigo-100"
											>Jump to...</span
										>
									</li>
								</a>
							{/each}
						</ul>
					</div>
				{/if}

				{#if hasSearched && !events.length && !venues.length && !performers.length && !loading}
					<!-- Empty state, show/hide based on command palette state. -->
					<div class="px-6 py-14 text-center sm:px-14">
						<Icon
							src={MagnifyingGlassCircle}
							class="mx-auto h-6 w-6 text-gray-400"
						/>
						<p class="mt-4 text-sm text-gray-900">
							We couldn't find any events with that term. Please try again.
						</p>
					</div>
				{/if}
				{#if value}
					<a
						href="/search?search={value}"
						class="group flex flex-wrap items-center bg-gray-100 px-5 py-4 text-sm text-gray-700 transition hover:bg-primary-200"
					>
						<Icon
							src={MagnifyingGlassCircle}
							class="mr-3 h-5  w-5 flex-none text-gray-400 group-hover:stroke-2 group-hover:text-primary-800 "
						/>
						<span>
							Search all results for <b>"{value}"</b>
						</span>
					</a>
				{/if}
			{/if}
		</div>
	</div>
</div>
